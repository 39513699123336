import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { cva } from 'class-variance-authority';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { Input } from '../../components/Input';
import { enviarDados } from '../../components/RDStation';
import { usePlanLoadingStore } from '../../stores/usePlanLoadingStore';
import { useRouteLoaderData } from 'react-router-dom';

const companyFormSchema = z.object({
  name: z.string().min(1, { message: 'Campo obrigatório' }),
  cnpj: z.string().min(1, { message: 'Campo obrigatório' }),
  email: z.optional(z.string()),
  phone: z.optional(z.string()),
  plan: z.optional(z.string()),
  planValue: z.optional(z.string()),
});
interface Data {
  name: string;
  cnpj: string;
  email: string;
  phone: string;
  plan: string;
  planValue: string;
}

interface Dados {
  email: string;
  nome: string;
  fone: string;
  valor: number;
  plano: string;
  prod_comercial: string;
  preco: number;
}

type CompanyFormSchema = z.infer<typeof companyFormSchema>;

const selectStyle = cva(
  'h-6 w-6 border-2 rounded-full flex justify-center items-center',
  {
    variants: {
      state: {
        active: 'border-primary-main',
        default: 'border-medium-emphasis',
      },
    },
    defaultVariants: {
      state: 'default',
    },
  },
);

const url = `${process.env.REACT_APP_OG_API_URL}/dentist`;
export default function CompanyForm(): ReactElement {
  const { register, handleSubmit, formState } = useForm<CompanyFormSchema>({
    defaultValues: {},
    resolver: zodResolver(companyFormSchema),
  });

  const { errors } = formState;
  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);
  const [load, setLoad] = useState(false);
  const [hasPlan, setHasPlan] = useState(false);
  const setLoading = usePlanLoadingStore(state => state.setLoading);
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');

  useEffect(() => {
    setLoading(false);
  }, [hasPlan]);

  function submitForm(event) {
    event.preventDefault();
    handleSubmit(async (data: Data) => {
      try {
        setLoad(true);
        const Dados: Dados = {
          email: data?.email ?? '',
          nome: data?.name ?? '',
          fone: data?.phone ?? '',
          valor: Number.parseFloat(data?.planValue.replace(',', '.')) ?? null,
          plano: data?.plan ?? null,
          prod_comercial: bussinessPlanData.produtoComercial.nm_prodcomerc,
          // eslint-disable-next-line prettier/prettier
          preco: Number.parseFloat(
            bussinessPlanData.produtoComercial.formasPagamentoEmpresa[0].vl_valor.replace(
              ',',
              '.',
            ),
          ),
        };

        const resp = await enviarDados(Dados);
        setResp({
          done: true,
          error: false,
          msg: resp.message ?? 'Indicação Enviada Com Sucesso!!!',
        });
      } catch (err) {
        setResp({
          done: true,
          error: true,
          msg: 'Erro ao enviar mensagem verifique os campos e tente novamente.',
        });
      } finally {
        setLoad(false);
      }
    })();
  }

  return (
    <form id="company-form" onSubmit={submitForm}>
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-16">
          <Input
            label="Razão Social"
            placeholder="Razão Social"
            errorMessage={errors.name?.message}
            {...register('name')}
          />
          <Input
            label="CNPJ"
            placeholder="00.000.000/0000-00"
            activateMask
            inputMode="numeric"
            mask={'99.999.999/9999-99'}
            errorMessage={errors.cnpj?.message}
            {...register('cnpj')}
          />
        </div>

        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Email"
            placeholder="sample@mail.com"
            errorMessage={errors.email?.message}
            {...register('email')}
          />
        </div>

        <div className="grid grid-cols-1 gap-16">
          <Input
            label="Telefone"
            placeholder="(00) 00000-0000"
            mask={'(99) 99999-9999'}
            errorMessage={errors.phone?.message}
            {...register('phone')}
          />
        </div>

        <div className="bg-neutral-surface rounded-lg p-5">
          <p className="text-high-emphasis text-base mb-4">
            A empresa já possui plano odontologico?
          </p>
          <div className="flex gap-5">
            <div
              className="flex gap-3 items-center cursor-pointer"
              onClick={() => setHasPlan(true)}
            >
              <div
                className={selectStyle({
                  state: hasPlan ? 'active' : 'default',
                })}
              >
                {hasPlan && (
                  <div className="w-3 h-3 rounded-full bg-primary-main" />
                )}
              </div>
              <p className="text-sm text-medium-emphasis">Sim</p>
            </div>
            <div
              className="flex gap-3 items-center cursor-pointer"
              onClick={() => setHasPlan(false)}
            >
              <div
                className={selectStyle({
                  state: !hasPlan ? 'active' : 'default',
                })}
              >
                {!hasPlan && (
                  <div className="w-3 h-3 rounded-full bg-primary-main" />
                )}
              </div>
              <p className="text-sm text-medium-emphasis">Não</p>
            </div>
          </div>
        </div>

        {hasPlan && (
          <div className="grid grid-cols-2 gap-8">
            <Input
              label="Qual o plano atual?"
              placeholder="plano"
              errorMessage={errors.plan?.message}
              {...register('plan')}
            />
            <Input
              label="Qual o valor do plano atual?"
              placeholder="0,00"
              errorMessage={errors.planValue?.message}
              {...register('planValue')}
            />
          </div>
        )}
      </div>
      {resp.done && (
        <p
          className={`mt-1 mb-2 text-base ${
            !resp.error ? 'text-success-main' : 'text-destructive-main'
          }`}
        >
          {resp.msg}
        </p>
      )}
      <Button
        disabled={load}
        className="min-[748px]:ml-auto max-[748px]:w-full mt-6"
        text="Enviar"
        intent="primary"
        Icon={ArrowRightIcon}
        type="submit"
      />
    </form>
  );
}
