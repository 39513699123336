import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
  UseFormTrigger,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  funcionarioSchema,
  FuncionarioSchema,
} from '../components/HirePlanForm/Bussiness-steps/data/schema/functionarysSchema';

import { zodResolver } from '@hookform/resolvers/zod';

/* import {
  creditCardSchema,
  CreditCardSchema,
} from '../components/HirePlanForm/Bussiness-steps/data/schema/creditCardSchema';
import {
  debitSchema,
  DebitSchema,
} from '../components/HirePlanForm/Bussiness-steps/data/schema/debitSchema'; */
import {
  billetSchema,
  BilletSchema,
} from '../components/HirePlanForm/Bussiness-steps/data/schema/billetSchema';

import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';
import { usePaymentStore } from '../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useLoadingStore } from '../stores/useLoadingStore';
import { useError } from '../stores/useError';
import { useVidaCountStore } from '../stores/useQuantidadeVidas';

type PaymentFormSchema = BilletSchema;
type funcionario = FuncionarioSchema;
interface HirePlanFormContextProps {
  register: UseFormRegister<PaymentFormSchema>;
  control: Control<PaymentFormSchema, any>;
  watch: UseFormWatch<PaymentFormSchema>;
  handleSubmit: UseFormHandleSubmit<PaymentFormSchema>;
  onSubmit: (schema: PaymentFormSchema) => void;
  goToNextForm: (schema: PaymentFormSchema) => void;
  errors: FieldErrors<PaymentFormSchema>;
  clearErrors: UseFormClearErrors<PaymentFormSchema>;
  unregister: UseFormUnregister<PaymentFormSchema>;
  setValue: UseFormSetValue<PaymentFormSchema>;
  funcionario: UseFormRegister<funcionario>;
  watchFunc: UseFormWatch<funcionario>;
  setFuncValue: UseFormSetValue<funcionario>;
  errorsFunc: FieldErrors<funcionario>;
}

const HirePlanFormContext = createContext({} as HirePlanFormContextProps);

interface HirePlanFormProviderProps {
  children: ReactNode;
}

export function HirePlanBussinessFormProvider({
  children,
}: HirePlanFormProviderProps): ReactElement {
  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const paymentId = usePaymentStore(state => state.id);
  const navigate = useNavigate();
  const qtdVidas = useVidaCountStore(x => x.count);

  const setLoading = useLoadingStore(state => state.setLoading);
  const setError = useError(state => state.setError);
  const setTitle = useError(state => state.setErrorTitle);
  const setText = useError(state => state.setErrorText);

  interface CheckoutData {
    token?: string;
    responsavelEmpresa?: object;
    empresa?: object;
    vencimentoBoleto?: string;
    funcionarios?: object;
  }
  interface responsavelEmpresa {
    nome?: string;
    cpf?: string;
    dataNascimento?: string;
    email?: string;
    telefone?: string;
  }

  interface Empresa {
    cnpj?: string;
    razaoSocial?: string;
    nomeFantasia?: string;
    inscricaoEstadual?: string;
    quantidadeFuncionarios?: number;
    nomeResponsavel?: string;
    cpfResponsavel?: string;
    email?: string;
    dddfixo?: string;
    telefoneFixo?: string;
    dddCelular?: string;
    celular?: string;
    cep?: string;
    logradouro?: string;
    numero?: number;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    idUf?: number;
    idPatrocinio?: number;
  }

  function getSchemaResolver() {
    return billetSchema;
  }
  const {
    register: funcionarioRegister,
    formState: { errors: funcionarioErrors },
    watch: funcionarioWatch,
    setValue: funcionarioSetValue,
  } = useForm<funcionario>({
    resolver: zodResolver(funcionarioSchema),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<PaymentFormSchema>({
    resolver: zodResolver(getSchemaResolver()),
  });
  const goToNextForm: SubmitHandler<PaymentFormSchema> = () => {
    goToNextStep();
  };
  const trigger: SubmitHandler<funcionario> = () => {
    const ok = 'ok';
  };

  const onSubmit: SubmitHandler<PaymentFormSchema> = async data => {
    /* const banco = watch('payment.bank');
    const conta = watch('payment.debitAcount');
    const agencia = watch('payment.agency');
    const operacao = watch('payment.operation');
    const primeiraBoleto = watch('payment.firstPaymentBillet');*/
    const vencimentoBoleto = watch('payment.billetDate');
    /* const vencimentoDebito = watch('payment.debitDate'); */
    //const payment = getPaymentData(data);
    const payment = {
      formaPagamento: {
        gpPagto: 3,
        idPagto: 4,
      },
    };

    /* const financialResponsible =
      data.user.beneficiaryIsSameAsFinancialResponsible == true
        ? {
            responsavelFinanceiro: {
              cpf: data.user.bussinessResponsible?.cpf?.replace(
                /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
                '$1$2$3$4',
              ),
              nome: data.user.fullName,
              dataNascimento: data.user.birthDate,
              email: data.user.email,
              cep: data.user.address?.cep,
              endereco: data.user.address?.street,
              cidade: data.user.address?.city,
              numero: data.user.address?.number,
              complemento: data.user.address?.complement,
              bairro: data.user.address?.neighborhood,
              idUf: data.user.address?.uf,
              telefone: data.user.phone,
            },
          }
        : {
            responsavelFinanceiro: {
              cpf: data.user.financialResponsible?.cpf?.replace(
                /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
                '$1$2$3$4',
              ),
              nome: data.user.financialResponsible?.fullName,
              dataNascimento: data.user.financialResponsible?.birthDate,
              email: data.user.financialResponsible?.email,
              cep: data.user.financialResponsible?.address?.cep,
              endereco: data.user.financialResponsible?.address?.street,
              cidade: data.user.financialResponsible?.address?.city,
              numero: data.user.financialResponsible?.address?.number,
              complemento: data.user.financialResponsible?.address?.complement,
              bairro: data.user.financialResponsible?.address?.neighborhood,
              idUf: data.user.financialResponsible?.address?.uf,
              telefone: data.user.financialResponsible?.phone,
            },
          }; */

    const responsavelEmpresa: responsavelEmpresa = {
      nome: data.user.fullName,
      cpf: data.user.documents?.cpf.replace(/[.-]/g, ''),
      dataNascimento: data.user.birthDate,
      email: data.user.email,
      telefone: data.user.phone,
    };
    const empresa: Empresa = {
      cnpj: data.user.documents.cnpj,
      razaoSocial: data.user.razSoc,
      nomeFantasia: data.user.razSoc,
      inscricaoEstadual: data.user.stateInscription,
      quantidadeFuncionarios: qtdVidas,
      nomeResponsavel: data.user.fullName,
      cpfResponsavel: data.user.documents.cpf.replace(/[.-]/g, ''),
      email: data.user.email,
      dddfixo: data.user.phone.substring(0, 2),
      telefoneFixo: data.user.phone.substring(2, 9),
      dddCelular: data.user.phone.substring(0, 2),
      celular: data.user.phone.substring(2, 9),
      cep: data.user.address.cep.replace(/[.-]/g, ''),
      logradouro: data.user.address.street,
      numero: parseFloat(data.user.address.number),
      complemento: data.user.address.complement,
      bairro: data.user.address.neighborhood,
      cidade: data.user.address.city,
      idUf: parseFloat(data.user.address.uf),
      idPatrocinio: parseFloat(data.payment.patrocinio),
    };

    const checkoutData: CheckoutData = {
      token: initialPlanData.token,
      responsavelEmpresa: responsavelEmpresa,
      vencimentoBoleto: vencimentoBoleto,
      empresa: empresa,
      funcionarios: data.user.funcionarios?.map(funcionario => ({
        nome: funcionario.nome,
        cpf: funcionario.cpf.replace(/[.-]/g, ''),
        rg: funcionario.rg,
        idOrgaoExpedidor: parseFloat(funcionario.orgao),
        idOrgaoExpedidorUf: parseFloat(funcionario.orgaoUF),
        cns: funcionario.cns,
        dataNascimento: funcionario.dtNascimento,
        nomeMae: funcionario.motherName,
        idSexo: funcionario.sexo,
        idParentesco: 1,
        dddFixo: funcionario.telefoneRes.substring(0, 2),
        telefoneFixo: funcionario.telefoneRes.substring(2, 9),
        dddCelular: funcionario.Celular.substring(0, 2),
        celular: funcionario.Celular.substring(2, 9),
        email: funcionario.email,
        matriculaFuncional: funcionario.matricula,
        cep: funcionario.address.cep,
        logradouro: funcionario.address.street,
        numero: parseFloat(funcionario.address.number),
        complemento: funcionario.address.complement,
        bairro: funcionario.address.neighborhood,
        cidade: funcionario.address.city,
        idUf: parseFloat(funcionario.address.uf),
        dependentes: funcionario.dependentes?.map(dependente => ({
          nome: dependente.nome,
          cpf: dependente.cpf.replace(/[.-]/g, ''),
          rg: dependente.rg,
          idOrgaoExpedidor: dependente.orgao,
          cns: dependente.cns,
          dataNascimento: dependente.dtNascimento,
          nomeMae: dependente.motherName,
          idSexo: dependente.sexo,
          idParentesco: dependente.parentesco,
          email: dependente.email,
          documentoVinculo: dependente.vinculo.file,
        })),
      })),
    };
    setLoading(true);
    const planValue = await fetch(
      `${process.env.REACT_APP_OG_PAYMENT_COMPANY}`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(checkoutData),
      },
    )
      .then(async response => {
        if (response.ok) {
          const responseData = await response.json();
          setLoading(false);
          setError(false);
          return responseData;
        }
        const errorData = await response.json();
        if (errorData.code) {
          setTitle(errorData.code);
          setText(errorData.error);
          throw new Error(errorData.error);
        } else {
          setText(errorData.errors);
          throw new Error(errorData.errors);
        }
      })
      //.then(response => navigate('/proposta', { state: response }))
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <HirePlanFormContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        watch,
        onSubmit,
        goToNextForm,
        errors,
        clearErrors,
        unregister,
        setValue,
        funcionario: funcionarioRegister,
        watchFunc: funcionarioWatch,
        setFuncValue: funcionarioSetValue,
        errorsFunc: funcionarioErrors,
      }}
    >
      {children}
    </HirePlanFormContext.Provider>
  );
}

export function useHireBussinessPlanForm(): HirePlanFormContextProps {
  return useContext(HirePlanFormContext);
}
