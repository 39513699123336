import { ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';
import { BeneficiaryRegisterForm } from './steps/BeneficiaryRegisterForm';
import { DependentsForm } from './steps/DependentsForm';
import { FinancialResponsibleForm } from './steps/FinancialResponsibleForm';
import { PaymentMethodForm } from './steps/PaymentMethodForm';
import { PlanBoostForm } from './steps/PlanBoostForm';
import { useRouteLoaderData } from 'react-router-dom';

export function HirePlanFormStepHandler(): ReactElement {
  const step = useHirePlanFormStepStore(state => state.step);
  //const data: any = useRouteLoaderData('token');

  /* if (
    data.produtoComercial.id_modproduto_c == 1 ||
    data.produtoComercial.id_modproduto_c == 2
  ) { */
  //OdontoClinico e OdontoDOC tem turbine o seu plano
  switch (step) {
    case 1: {
      return <BeneficiaryRegisterForm />;
    }

    case 2: {
      return <DependentsForm />;
    }

    case 3: {
      return <PlanBoostForm />;
    }

    case 4: {
      return <PaymentMethodForm />;
    }

    default: {
      return <BeneficiaryRegisterForm />;
    }
  }
  /*   } else {
    switch (step) {
      case 1: {
        return <BeneficiaryRegisterForm />;
      }

      case 2: {
        return <DependentsForm />;
      }

      case 3: {
        return <PaymentMethodForm />;
      }

      default: {
        return <BeneficiaryRegisterForm />;
      }
    }
  } */
}
