import {
  type ReactElement,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react';

import { type State, type City, getStates } from '../../services/StatesService';
import Search from './Search';

export interface Specialty {
  idEspecialidade: number;
  nomeEspecialidade: string;
  quantidade: number;
}

interface Plan {
  id: number;
  name: string;
}

export interface Dentist {
  bairro: string;
  cep: string;
  cidade: string;
  complemento?: string;
  especialidade: string;
  idEspecialidade: string;
  idFuncionario: number | string;
  idPlano: null;
  latitude: string;
  logradouro: string;
  longitude: string;
  nomeFuncionario: string;
  numero?: number | string;
  telefone: { telefone: string; tipo: string }[];
  tipoDeLogradouro: 'Rua';
  ufSigla: 'PERNAMBUCO';
  nomeFantasia?: string;
  tipoEstabelecimento?: string;
  qualiss?: string;
  produtos?: string;
}

interface DentistsContext {
  states: State[];
  specialties: Specialty[];
  specialty: Specialty;
  city: City;
  state: State;
  plan: Plan;
  plans: Plan[];
  loading: boolean;
  found: boolean;
  docList: Dentist[];
  canSearch: boolean;
  handleSearch: () => void;
  setSpecialty: React.Dispatch<React.SetStateAction<Specialty>>;
  setState: React.Dispatch<React.SetStateAction<State>>;
  setCity: React.Dispatch<React.SetStateAction<City>>;
  setPlan: React.Dispatch<React.SetStateAction<Plan>>;
  reset: () => void;
}

const DentistsContext = createContext({} as DentistsContext);

export const useDentistsContext = (): DentistsContext =>
  useContext(DentistsContext);

export default function DentistSearch(): ReactElement {
  const [states, setStates] = useState<State[]>([]);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [specialty, setSpecialty] = useState<Specialty>(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<State>(null);
  const [city, setCity] = useState<City>(null);
  const [plan, setPlan] = useState<Plan>(null);
  const [found, setFound] = useState(false);
  const [docList, setDocList] = useState<Dentist[]>([]);
  const [dentista, setDentista] = useState<any>([]);

  useEffect(() => {
    getStates().then(states => setStates(states));

    const getRequest = async url => {
      const resp = await fetch(url).then(resp => resp.json());
      return resp.dataObject.dados;
    };

    getRequest(
      `${process.env.REACT_APP_GET_DENTISTS_SPECIALTY}?token=${process.env.REACT_APP_TOKEN_S4E}&sigla=df`,
    ).then(s => setSpecialties(s));
    setPlans([
      { id: 1, name: 'OdontoClínico' },
      { id: 2, name: 'OdontoDoc' },
      { id: 3, name: 'OdontoOrto' },
      { id: 4, name: 'OdontoPrótese' },
      { id: 5, name: 'OdontoKids' },
    ]);
  }, []);

  useEffect(() => {
    const postRequest = async (url, data) => {
      const resp = await fetch(url, {
        method: 'POST', // Define o método como POST
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Converte o corpo da requisição para JSON
      }).then(resp => resp.json());
      return resp.dados;
    };
    if (specialty && state && city && plan) {
      const data = {
        plano: plan.id,
        uf: state.Id,
        municipio: city.Id,
        especialidade: specialty.idEspecialidade,
      };
      postRequest(
        `${process.env.REACT_APP_GET_DENTISTS_BY_SPECIALTY}?token=${process.env.REACT_APP_TOKEN_S4E_V1}`,
        data,
      ).then(d => setDentista(d));
    }
  }, [specialty, state, city, plan]);

  function handleSearch() {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_GET_ACTIVE_DENTISTS}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idEstado=${state.Id}&idCidade=${city.Id}&pagina=${1}`,
    )
      .then(resp => resp.json())
      .then(data => {
        const c = data.dataObject.dados
          .filter(d => d.longitude)
          .map(d => ({
            ...d,
            longitude: d.longitude.replace(',', '.'),
            latitude: d.latitude.replace(',', '.'),
          }));
        const decide = c.map(retorno =>
          fetch(
            `${process.env.REACT_APP_POST_REDE_ATENDIMENTO}?token=${process.env.REACT_APP_TOKEN_S4E_V1}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ dentista: retorno.nomeFuncionario }),
            },
          )
            .then(response => response.json())
            .then(postData => {
              if (postData?.dados != null && postData?.dados?.length > 0) {
                const ret = postData.dados[0];
                return {
                  ...retorno,
                  nomeFantasia: ret.NomeFantasia ? ret.NomeFantasia : '',
                  tipoEstabelecimento: 'Clínica',
                  //qualiss: ret.Qualiss.length > 0 ? ret.Qualiss : '',
                };
              } else {
                return {
                  ...retorno,
                };
              }
            }),
        );
        Promise.all(decide)
          .then(updatedDentists => {
            setDocList(updatedDentists);
          })
          .catch(err => {
            console.log(err);
            setDocList(c);
          });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setFound(true);
      });
  }

  const reset = () => {
    setFound(false);
    setDocList([]);
    setCity(null);
    setState(null);
    setSpecialty(null);
    setPlan(null);
    setLoading(false);
  };

  const canSearch = !!specialty && !!plan && !!state && !!city && !loading;
  return (
    <DentistsContext.Provider
      value={{
        states,
        docList,
        plan,
        specialties,
        found,
        plans,
        specialty,
        loading,
        state,
        city,
        canSearch,
        setSpecialty,
        handleSearch,
        setState,
        setPlan,
        setCity,
        reset,
      }}
    >
      <Search />
    </DentistsContext.Provider>
  );
}
