import { create } from 'zustand';

type Payment = {
  id: number;
  setId: (id: number) => void;
};

export const usePaymentStore = create<Payment>(set => ({
  id: 1,
  setId: id => set(() => ({ id: id })),
}));

type ValorTotal = {
  valor: string;
  setValor: (valor: string) => void;
};

export const useValorTotalStore = create<ValorTotal>(set => ({
  valor: '',
  setValor: valor => set(() => ({ valor: valor })),
}));
