import { ReactElement, useEffect, useState } from 'react';

import { useHireBussinessPlanForm } from '../../../contexts/HirePlanBussinessFormProvider';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { GoForwardButton } from '../GoForwardButton';
import { useRouteLoaderData } from 'react-router-dom';
import { validateCEP } from '../../../utils/ValidateCEP';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeUF,
} from '../../../utils/ChangeArrays';
import {
  IndividualWithShortageNorthEastTokens,
  IndividualWithShortageTokens,
  IndividualWithoutShortageTokens,
} from '../../../utils/Enums/Tokens';
import { usePlanLoadingStore } from '../../../stores/usePlanLoadingStore';
import { validateCPF } from '../../../utils/ValidateCPF';
import { getPlanDetails } from '../../../requests/requests';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import Loading from '../../icons/Loading';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';

export function BussinessRegisterForm(): ReactElement {
  const { register, handleSubmit, goToNextForm, errors, watch, setValue } =
    useHireBussinessPlanForm();
  const initialPlanData: any = useRouteLoaderData('token-empresa');
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);
  const setNortheastPlanData = useNortheastPlanDataStore(
    state => state.setNortheastPlanData,
  );
  const setLoading = usePlanLoadingStore(state => state.setLoading);

  useEffect(() => {
    setLoading(false);
  }, []);

  const CEP = watch('user.address.cep');
  const CPF = watch('user.documents.cpf');
  const UF = watch('user.address.uf');
  const city = watch('user.address.city');
  const neighborhood = watch('user.address.neighborhood');
  const street = watch('user.address.street');

  const [data, setData] = useState(initialPlanData);
  const [newData, setnewData] = useState({});

  const getCEPDetails = async (e: string) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());

    setLoading(false);

    if (CEPInformations.status === 500 || CEPInformations.status === 404) {
      throw new Response('Not Found', { status: 404 });
    } else if (CEPInformations.erro) {
      setLoading(true);
    }
    const UF = data.listaUFS.find(x => x.sigla == CEPInformations.uf).Id;
    setValue('user.address.city', CEPInformations.localidade);
    setValue('user.address.neighborhood', CEPInformations.bairro);
    setValue('user.address.street', CEPInformations.logradouro);
    setValue('user.address.uf', String(UF));

    return CEPInformations;
  };

  useEffect(() => {
    if (Object.keys(newData).length !== 0 && newData != data) {
      setData(newData);
    }
  }, [newData]);

  useEffect(() => {
    if (CEP && validateCEP.test(CEP)) {
      getCEPDetails(CEP);
    }
  }, [CEP]);

  useEffect(() => {
    if (Object.keys(northeastPlanData).length === 0) {
      setNortheastPlanData({ ...initialPlanData });
    }
  }, []);
  changeUF(data.listaUFS);
  changeGender(data.listaSexos);
  changeIssuingAgency(data.listaOrgaosExpedidor);
  changeCivilStatus(data.listaEstadosCivil);
  return (
    <form
      action=""
      className="max-w-[766px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8">
        Dados Empresa
      </h2>

      <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-6">
        <Input
          activateMask
          mask="99999-999"
          label="CEP"
          className="w-full"
          //onBlur={e => getPlanValueByCEP(e.target.value)}
          placeholder="CEP"
          errorMessage={errors.user?.address?.cep?.message}
          {...register('user.address.cep')}
        />
        <Input
          label="CNPJ"
          placeholder="CNPJ"
          activateMask
          mask="99.999.999/9999-99"
          errorMessage={errors.user?.documents?.cnpj?.message}
          {...register('user.documents.cnpj')}
        />
        <Input
          label="Razão Social"
          placeholder="Razão Social"
          errorMessage={errors.user?.razSoc?.message}
          {...register('user.razSoc')}
        />
        <Input
          label="Inscrição Estadual"
          placeholder="Inscrição Estadual"
          errorMessage={errors.user?.stateInscription?.message}
          {...register('user.stateInscription')}
        />
        <Input
          label="Número de Funcionários"
          placeholder="Número de Funcionários"
          errorMessage={errors.user?.functionaryQuantity?.message}
          {...register('user.functionaryQuantity')}
        />
      </div>
      <h2 className="min-[748px]:text-xl text-lg font-medium text-primary-main mb-8 mt-16">
        Dados do Responsável pela empresa
      </h2>
      <div className="grid min-[748px]:grid-cols-1 grid-cols-1 gap-6">
        <div className="grid grid-cols-1 gap-8">
          <Input
            label="Nome completo"
            placeholder="Nome completo"
            errorMessage={errors.user?.fullName?.message}
            {...register('user.fullName')}
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Input
            label="CPF"
            placeholder="CPF"
            onBlur={validateCPF}
            activateMask
            mask="999.999.999-99"
            errorMessage={errors.user?.documents?.cpf?.message}
            {...register('user.documents.cpf')}
          />
          <Input
            label="E-mail"
            placeholder="E-mail"
            errorMessage={errors.user?.email?.message}
            {...register('user.email')}
          />
          <Input
            label="Telefone"
            activateMask
            mask="(99) 99999-9999"
            placeholder="Telefone"
            errorMessage={errors.user?.phone?.message}
            {...register('user.phone')}
          />
        </div>
        <Input
          label="Logradouro"
          //value={street}
          placeholder="Digite o seu logradouro"
          errorMessage={errors.user?.address?.street?.message}
          {...register('user.address.street')}
        />
        <div className="grid grid-cols-2 gap-8">
          <Input
            label="Complemento"
            placeholder="Digite o seu complemento"
            errorMessage={errors.user?.address?.complement?.message}
            {...register('user.address.complement')}
          />
          <Input
            label="Número"
            //type="number"
            placeholder="Digite o seu número"
            errorMessage={errors.user?.address?.number?.message}
            {...register('user.address.number')}
          />
        </div>
        <div className="grid grid-cols-3 gap-8">
          <Input
            label="Bairro"
            //value={neighborhood}
            placeholder="Digite o seu bairro"
            errorMessage={errors.user?.address?.neighborhood?.message}
            {...register('user.address.neighborhood')}
          />
          <Input
            label="Cidade"
            //value={city}
            placeholder="Digite sua cidade"
            errorMessage={errors.user?.address?.city?.message}
            {...register('user.address.city')}
          />
          <Select
            label="UF"
            options={[]}
            optionsFull={data.listaUFS}
            placeholder="UF"
            errorMessage={errors.user?.address?.uf?.message}
            {...register('user.address.uf')}
          />
        </div>
      </div>
      <GoForwardButton />
    </form>
  );
}
