import { type ReactElement, useState } from 'react';

import { useDentistsContext } from '../';
import { type City, getCities } from '../../../services/StatesService';
import { Select } from '../../Select';

export default function PlanForm(): ReactElement {
  const [cities, setCities] = useState<City[]>([]);

  const [resp, setResp] = useState<{
    done: boolean;
    error: boolean;
    msg: string;
  }>({} as any);

  const { states, plans, state, city, setCity, setState, plan, setPlan } =
    useDentistsContext();

  function submitForm(event) {
    event.preventDefault();
  }

  function onStateChange(event) {
    const ID = event.target.value;
    setCity(null);
    getCities(ID).then(cities => {
      setCities(cities);
      setCity(cities[0]);
    });
    setState(states.find(s => s.Id === +ID));
  }

  return (
    <div className="min-w-[50%] max-[748px]:w-full">
      <h3 className="text-2xl max-[1300px]:text-lg text-high-emphasis font-normal mb-5">
        Onde você gostaria de ser atendido? &nbsp;
        <span className="max-[748px]:hidden">Qual seu plano?</span>
      </h3>
      <form id="dentist-search-plan-form" onSubmit={submitForm}>
        <div className="min-[748px]:min-w-[40%] grid gap-8 min-[748px]:grid-cols-4 grid-cols-1">
          <div>
            <Select
              label="Estado"
              placeholder="Estado"
              options={[]}
              value={state?.Id}
              optionsFull={states}
              onChange={onStateChange}
            />
          </div>
          <div>
            <Select
              label="Cidade"
              placeholder="Cidade"
              value={city?.Id}
              onChange={e =>
                setCity(cities.find(c => c.Id === +e.target.value))
              }
              options={[]}
              optionsFull={cities}
            />
          </div>
          <div className="min-[748px]:col-span-2">
            <Select
              label="Selecione seu plano"
              options={[]}
              value={plan?.id}
              optionsFull={plans.map(p => ({
                Id: p.id,
                Sigla: p.name,
              }))}
              placeholder="Plano"
              onChange={e => setPlan(plans.find(p => p.id === +e.target.value))}
            />
          </div>
        </div>
        {resp.done && (
          <p
            className={`mt-1 mb-2 text-base ${
              !resp.error ? 'text-success-main' : 'text-destructive-main'
            }`}
          >
            {resp.msg}
          </p>
        )}
      </form>
    </div>
  );
}
