import { ReactElement } from 'react';

import { Button } from '../../Button';
import { CopyPasteIcon } from '../../icons/CopyPasteIcon';
import { PaymentCoinIcon } from '../../icons/PaymentCoinIcon';
import { LabelledInformationProposal } from '../LabelledInformationProposal';
import { Link } from 'react-router-dom';

type Props = {
  data: {
    paymentStatus: number;
    formaPagamento: string;
    grupoPagamento: number;
    linkPagamento: string;
  };
};

export function PaymentSectionProposal(state: Props): ReactElement {
  let paymentStatus = 'Pendente';

  if (state.data.grupoPagamento == 1 || state.data.grupoPagamento == 3) {
    switch (state.data.paymentStatus) {
      case 0: {
        break;
      }
      case 1 || 2: {
        paymentStatus = 'Pago';
        break;
      }
    }
  } else {
    switch (state.data.paymentStatus) {
      case 0 || 1: {
        break;
      }
      case 2: {
        paymentStatus = 'Pago';
        break;
      }
    }
  }
  return (
    <section className="p-4 min-[748px]:max-h-[140px] h-full rounded-xl mt-8 border border-[#D8D8D8] flex max-[748px]:flex-col gap-6 justify-between">
      <div>
        <h2 className="text-xl text-[#0B0B0B] mb-6">Pagamento</h2>
        <ul className="flex max-[748px]:flex-col gap-8">
          <LabelledInformationProposal
            label="Status de pagamento"
            value={paymentStatus}
            valueClassName="bg-[#FAE9BE] py-1 px-2 rounded-[40px] mt-1"
          />
          <LabelledInformationProposal
            label="Formato"
            value={state.data.formaPagamento}
          />
        </ul>
      </div>
      {state.data.grupoPagamento == 3 && (
        <div className="flex flex-col gap-3">
          {/* <Button text="Copiar link" intent="secondary" Icon={CopyPasteIcon} /> */}
          <Button
            text={
              <Link to={state.data.linkPagamento} target="_blank">
                Efetuar pagamento
              </Link>
            }
            Icon={PaymentCoinIcon}
          />
        </div>
      )}
    </section>
  );
}
